import * as _ from 'lodash';
import {Moment} from 'moment';
import {AddOn} from '@app/entities/add-on';
import {environment} from '#env';
import * as moment from 'moment';

const country = environment.currency.country;
const currency = (iso = 2) => {
  let c = iso === 2 ? 'RM' : 'MYR';
  const user = JSON.parse(localStorage.getItem('currentUser'));
  if (user && user.setting) {
    c = iso === 2 ? user.setting.currency2 || 'RM' : user.setting.currency3 || 'MYR';
  }
  return c;
};

export class OrderItem {
  _id: string;
  orderId: string;
  userId: string;
  supplierId: string;
  supplierName: string;
  name: string;
  foodItemId: string;
  quantity: number;
  total: number;
  status: string;
  recommId: string;
  description: string;

  restaurantPrice: number;
  marginFromRestaurant: number;
  loyaltyMargin?: number;
  rewardPointsAmount: number;
  gmv: number;
  nmv: number;
  boughtAsGold: boolean;
  group: boolean;

  // backward compatibility
  amount: number;

  // other utilities
  loading: boolean;
  deliveryName: string;
  dailyMenuId: string;
  dailyMenuForDate: string;
  userName?: string;
  nmv_gold?: number;
  addons?: AddOn[];
  specialInstructions: string;
  foodItemCustoms?: Customization[];
  rated?: number;
  billed = true;

  otherBranches?: any[];
  foodItem?: FoodItem;
  supplier?: Supplier;
  supplierLoyaltyReward?: number;
  sst?: number;
  commission?: number;
  deliveryFee?: number;
  mDeliveryFee?: number;
  distributedPCV?: number;
  distributedSD?: number;
  supplierLoyaltyPercentage?: number;
  marginFromPaymentGateway?: number;
  agreementDate?: Date;

  sstVendor?: number;
  sstClient?: number;
  commissionSST? :number;
  totalSellingPrice? :number;
  totalRetailPrice? :number;
  totalRestaurantPrice? :number;
  loyaltyByClient?: number;
  loyaltyByVendor?: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface Addons {
  _id: string;
  gmv: number;
  nmv: number;
  nmv_gold: number;
  restaurantPrice?: number;
  marginFromRestaurant?: number;
  name: string;
  category: string;
  gold: boolean;
  qty: number;
  supplierId: string;
  total: number;
}

export class Order {
  _id: string;
  isSelected =false;
  cancelReason: string;
  cancelUser: string;
  quotationNumber?: string;
  validQuotationNumber = false;
  dailyMenuId = '';
  dailyMenuForDate;
  dailyMenuForDateEnd;
  type: string;
  channel: string;
  createdAt: Date;
  updatedAt: Date;
  mobileNumber: string;
  sstApplied?: boolean;
  sstRate?: number;
  totalAmount: number;
  amountPaid: number;
  amountDue: number;
  customerCareFee: number;
  refundAmount: number;
  refundPaidByVendor: boolean;
  refundType: string;
  totalGMV?: number;
  totalNMV?: number;
  REDEEMED?: number;
  LOYALTY?: number;
  LOYALTYRM?: number;
  RC?: number;
  RC1?: number;
  SF?: number;
  CCC?: number;
  SST?: number;
  GM1?: number;
  GM2?: number;
  PCV?: number;
  PCP?: number;
  PCS?: number;
  RP?: number;
  SMARTDISCOUNT?: number;
  COMMISSION?: number;
  INVOICETAX?: number;
  CATERERTOTALDUE?: number;
  PAYMENTGATEWAYFEE?: number;
  RETAIL?: number;
  SELLING?: number;
  TAKERATE?: number;
  FINAL?: number;
  margin?: number;
  totalItems: number;
  userId: string;
  userName: string;
  userMobileNumber: string;
  conciergeRequestId: string;
  userEmail: string;
  companyName = '';
  buildingId = '';
  buildingName = '';
  floor = '';
  unitNumber = '';
  blockNumber = '';
  status = 'processing';
  peopleCount?: number;
  zoneId: string;
  zoneName: string;
  sublocalityId: string;
  sublocalityName: string;
  paidStatus: string;
  paymentMethod: string;
  paymentStatus: string;
  paymentUrl?: string;
  orderNumber: string;
  referralOrder: boolean;
  differentPickup: boolean;
  feedbackSuggestion: string;
  feedbackHandled: boolean;
  overallRating: number;
  deliveryRating: number;
  timeSlot: string;
  subTimeSlot: number;
  group: any;
  address: string;
  fee: number;
  extraFees: number;
  extraCosts: number;
  extraDescription: string;
  mDeliveryFee: number;
  deliveryFee: number;
  deliveryFeeToVendor: number;
  deliveryCost: number;
  rewardPointsAmount = 0;
  gst: number;
  totalDiscountedAmount: number;
  totalSmartDiscountedAmount: number;
  totalOfferDiscountedAmount: number;
  totalCorporateAllowance: number;
  nuggetsAmount: number;
  groupTitle?: string;
  groupDescription?: string;
  groupAutoConfirm?: boolean;
  groupCutoff?: Date;
  promo: any;
  promoId: any;
  discountedAmount?: number;
  boughtAsGold?: boolean;
  building: any;
  ratingSkipped = false;
  rid: string;
  buildingPlaceId: string;
  promoType: string;
  promoReferredId: string;

  orderItems: OrderItem[] = [];
  invoice?: any;
  reviews?: SupplierReviews[];
  loading = false;
  deliveryHistory?: any[] = [];
  lastDeliveryStatus?: string;
  trackingUrl?: string | null;
  orderType?: string;
  deliveryRemark?: string;
  ItemStatus:string ;
  // corporate
  corporateId?: string;
  locationId: string;
  placeId?: string;

  sessionToken?: string;
  jobs?: any;
  extraCharges?: any;
  orderHistory?: any[];
  corporateReports?: any[];
  documents?: any[];
  corporateInvoice?: any;
  tip?: number;
  purchaseOrderNumber?: string;
  personIncharge?: string;
  otherDetails?: string;
  customFieldsSet?: CustomFieldValue[]

  refunds: [{
    refundCategory: string;
    refundPercentage: string;
    refundPercentageCustom: string;
  }];
  deliveryOrderId?: string;
  deliveryOrderStatus?: string;
  noOfRevealPhoneNumberByVendor?: number;
  customer?: any;
  updatedFields?: any;
  taxApplied?: boolean;
  orderForElse?: {
    full_name: string;
    mobile_number: string;
    email: string;
  };

  serviceProvider?: string;
  isPastTime?: boolean;
  isReviewAvailable?: boolean;

  isOnTime?: boolean;
  parentId?: string;

  isQRRedeem?: boolean;
  qrRedeemDate?: string;

  managerName?: string;
  managerEmail?: string;
  managerMobileNumber?: string | number;

  corporatePIC?: string;
  itemstatus: any;
  doorToDoorService?: boolean;

  sellingPrice?: number;
  retailPrice?: number;
  serviceCharge?: number;
  creditCardCharges?: number;
  sstVendor?: number;
  sstClient?: number;
  takeRate?: number;
  final?: number;
  serviceCharges?: number;
  serviceChargesPercentage?: number;
  conciergeCharges?: number;
  gm1?: number;
  gm2?: number;
  loyaltyRedeemed?: number;
  grossRevenue?: number;
  loyaltyByVendor?: number;
  loyaltyByClient?: number;
  promoCodeVendor?: number;
  promoCodePartner?: number;
  promoCodeSmartBite?: number;
  commission?: number;
  commissionSST?: number;
  markup?: number;
  caterTotalDue?: number;
  totalRevenue?: number;
  restaurantPrice?: number;
  gmv?: number;
  paymentGatewayFee?: number;
  isTaxExempted?: boolean;
  smartLogisticMargin?: number;
  gfv?: number;
  marginFromRestaurant?: number;
  marginFromPaymentGateway?: number;
  marginFromLoyalty?: number;
  smartDiscount?: number;
  offerDiscount?: number;
  penaltyAmount?: number;
  refundVendorAmount?: number;
  refundClientAmount?: number;
  finalPay?: number;
  amountPaidEWallet?: number;
  corporate?: Corporate;
  isDraft?: boolean;
  isParent?: boolean;
  isSelfDelivery?: boolean;
  slcToDeliveryFee?: number;
  smartLogisticCost?: number;
  deliveryType?: string;
  lateRemarks?: string;
  lateReason?: string;
  is20Percent?: boolean;
  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Delivery {
  _id: string;
  name: string;
  mobileNumber: string;
  password: string;
  status = 'active';
  maxGmv: number;
  maxDropOffs: number;
  availableInSlots: { timeSlot: string, subTimeSlots: { available: boolean, title: number }[] }[];
  availableForDays: {
    available: boolean, day: 0 | 1 | 2 | 3 | 4 | 5 | 6,
    openingSlots: { timeSlot: string, subTimeSlots: { available: boolean, title: number }[] }[]
  }[];
  showInReports: boolean;
  zones: string[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Zone {
  _id: string;
  name: string;
  city: string;
  status: string;
  sublocalities?: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class SubLocality {
  _id: string;
  name: string;
  status: string;
  city: string;
  zoneId: string;
  zoneName: string;
  buildings: number;
  zone?: Zone;
  bounds?: any;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class DailyMenu {
  _id: string;
  name: string;
  menuForDate: Date[];
  zoneId: string;
  zoneName: string;
  status = 'active';
  public = false;
  sliders: { _id: string, index: number, name: string }[] = [];
  isHoliday = false;
  message: string;
  zones: string[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class MenuItem {
  _id: string;
  name: string;
  status = 'active';
  dailyMenuId: string;
  dailyMenuForDate: Date[];
  supplierId: string;
  supplierName: string;
  foodItemId: string;
  foodItemName: string;
  orderIndex: number;
  category: 'main' | 'snack' | 'dessert' | 'drink' | 'cakes_pastries';
  itemType: string;
  description: string;
  images: string[] = [];
  timeSlot: 'breakfast' | 'lunch' | 'tea-break' | 'dinner';

  restaurantPrice: number;
  marginFromRestaurant: number;
  gmv: number;
  nmv: number;
  nmv_gold: number;
  gold: boolean;

  // backward compatibility
  sellingPrice: number;

  tagFoodType: string[];
  tagMealType: string[];
  tagDietary: string[];
  foodItemCustoms: Customization[] = [];

  constructor(values: Object = {}, images: string[] = []) {
    Object.assign(this, values, {images: images});
  }
}

export class Customization {
  _id: string;
  index: number;
  name: string;
  itemType: string;
  gold: boolean;
  require: boolean;
  minAllowed: number;
  maxAllowed: number;
  individualQuantity: boolean;
  included: number;
  deleted: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class FoodItem {
  _id: string;
  isSelected?:boolean;
  dailyMenuId?: string;
  foodItemId?: string;
  orderIndex?: number;
  numSold?: number;
  quantity?: number;
  name: string;
  slug: string;
  status = 'active';
  timeSlot: 'breakfast' | 'lunch' | 'tea-break' | 'dinner' | '' = 'lunch';
  supplierId: string;
  supplierName: string;
  category = [];
  itemType?: string;
  summary: string;
  description: string;
  ingredients?: string;
  weight?: string;
  seasonalTag?: string[];
  images = [];
  foodItemCoverImages = [];
  imageUrl?: string;
  updatedBy: string;
  updatedAt: Date;
  offer = false;
  buyOffer ?: number;
  getFreeOffer ?: number;
  gold = false;
  public = false;
  available = false;
  mostOrdered = false;
  deleted: boolean;
  section: string;
  subjectToStock: boolean;
  canBeDiscounted: boolean;

  restaurantPrice: number;
  marginFromRestaurant: number;
  gmv: number;
  nmv?: number;
  nmv_gold?: number;

  tagFoodType?: string[];
  tagMealType?: string[];
  tagDietary?: any[] = [];
  tagPriceRange?: string;
  tagIngredients?: string[];
  servesPeople?: number;
  minimumQuantity?: number;
  manageOnOutlet?: boolean;
  foodItemCustoms: Customization[] = [];

  items: any[];
  chainId: string;
  chainName: string;

  // backward compatibility
  simplePriceToSell: number;
  finalPrice: number;

  foodItemStocks: FoodItemStock[] = [];
  drafts: any[];
  createdBy?: string;
  action?: string;
  difference?: string[];
  createdAt?: Date;
  managerName?: string;
  menuSections?: any;
  supplierStock?: any;

  fileDataUri?: any;
  fileDataCoverUri?: any;
  loadingImage: boolean = false;
  loadingCImage: boolean = false;
  isNew: boolean = true;

  constructor(values: object = {}, imgs?: any[], coverImages?: any[]) {
    Object.assign(this, values);

    if (imgs) {
      this.images = imgs;
    }
    if (coverImages) {
      this.foodItemCoverImages = coverImages;
    }
  }
}

export class FoodItemStock {
  _id: string;
  foodItemId: string;
  startDate: Date;
  endDate: Date;
  createdAt: Date;
  stock: number;
  left: number;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class FoodCustomItem {
  _id: string;
  isSelected?: boolean;
  dailyMenuId?: string;
  foodItemId?: string;
  orderIndex?: number;
  name: string;
  status = 'active';
  supplierId: string;
  supplierName: string;
  category = '';
  itemType?: string;
  description: string;
  ingredients?: string;
  weight?: string;
  images = [];
  foodCustomItemCoverImages = [];
  updatedBy: string;
  updatedAt: Date;
  gold = false;
  available: boolean;

  restaurantPrice: number;
  marginFromRestaurant: number;
  gmv: number;
  nmv?: number;
  nmv_gold?: number;

  tagFoodType?: string[];
  tagMealType?: string[];
  tagDietary?: string[] = [];
  tagPriceRange?: string;
  tagIngredients?: string[];

  chainId: string;
  chainName: string;

  drafts: any[];
  createdBy?: string;
  action?: string;
  difference?: string[];
  createdAt?: Date;
  managerName?: string;
  manageOnOutlet?: Boolean;
  isMarkup?:Boolean;
  supplierStock?: any[];
  isNew?: boolean = true;

  constructor(values: object = {}, imgs?: any[], coverImages?: any[]) {
    Object.assign(this, values);

    if (imgs) {
      this.images = imgs;
    }
    if (coverImages) {
      this.foodCustomItemCoverImages;
    }
  }
}

export enum DeliveryServiceProvider {
  GOGET = 'goget',
  LALAMOVE = 'lalamove',
  HAPPYMOVE = 'happymove',
  RIDA = 'rida',
  ALLEASYGO = 'alleasygo',
  DELIVERIT = 'deliverit',
  FIVESENDS = '5sends',
  UPARCEL = 'uparcel',
  VENDOR = 'vendor'
}

export class SupplierClosedDate {
  begin: Date = new Date();
  end: Date = moment().add('day', 1).toDate();
  closureType: string = 'specific';
  selectedDays: number[];
  days?: string;
}

export class Supplier {
  _id: string;
  isSelected?: boolean;
  name = '';
  guid = '';
  status = 'active';
  vendorType: string;
  available: boolean;
  riderInfo: string;
  description: string;
  termsAndConditions: string;
  customersTermsAndConditions: string;
  notes: string;
  rating: number;
  address: string;
  deviceId?: string;
  notificationEmail?: string;
  notificationPhoneNumber?: string;
  placeId: string;
  loc = [];
  tagCuisine: string[];
  tagFormat: string[];
  tagMealType: string[];
  tagPriceRange: string;
  tagEnvironmentalPolicy: string[];
  tagDietary: string[];
  openingSlots: { timeSlot: string, subTimeSlots: { available: boolean, title: number }[] }[];
  availableForDays: {
    available: boolean,
    day: number,
    openingSlots: { timeSlot: string, subTimeSlots: { available: boolean, title: number }[] }[],
    openingTime: string,
    closingTime: string,
    openToday: boolean
  }[];
  notificationToken: string[];
  image: string;
  coverImage: string;
  publicPageLogo: string;
  sstNumber: string;
  pinned: boolean;
  sst: boolean;
  autoBookDelivery: boolean;
  plasticFreePackaging: boolean;
  companyNumber: string;
  companyName: string;
  paymentDetails: string;
  payWithinDays: string;
  acceptLatePaymentTerms: boolean;
  zones: string[];
  slug?: string;
  chain?: any;
  chainId?: string;
  inheritMenuFromChain = true;
  // others
  tags?: string[];
  noFoodItems?: number;
  lastReportAt: string;
  lastInvoiceAt: string;
  reports: any[];
  documents: any[];
  createdAt: Date;
  updatedAt: Date;
  lastSeen: Date;
  halal: boolean;
  type: string;
  certification:string;
  minimumOrder: number;
  maximumPax: number;
  operatingRadius: number;
  preorderTime: number;
  loyaltyPercentage: number;
  deliveryFeeBase: number;
  deliveryFeeMinRadius: number;
  deliveryFeePerKm: number;
  mDeliveryFee: number;
  // PIC refs
  picId: string;
  picName: string; // deprecated
  picMobileNumber: string; // deprecated
  picEmail: string; // deprecated
  minimumOrderFeeBase: number;
  minimumOrderMinRadius: number;
  minimumOrderPerKm: number;
  menuSections: SupplierMenuSection[] = [];
  menus: SupplierMenu[] = [];
  reviews: SupplierReviews[] = [];
  menuStatus: string;
  referralCode: string;
  margin: number;
  referralMargin: number;
  paymentGatewayMargin: number;
  requireOrderConfirmation: boolean;
  orderAcceptOptions: string;
  maximumPaxAutoAccept: number;
  specialRequirmentAutoAccept: boolean;
  maxPaxPerSlot?: number;
  closedDate: SupplierClosedDate[] = [];
  freeDeliveryAboveXAmount: number;
  vendorAccounts: VendorAccount[] = [];
  accounts: string[] = [];
  enablePickup: boolean;
  pic?: User;
  promotions: SupplierPromotionSection[] = [];
  googleReviews?: boolean;
  allowDeliveryBooking?: boolean;
  drafts: any[];
  createdBy?: string;
  action?: string;
  difference?: string[];
  managerName?: string;
  primaryColor?: string;
  secondaryColor?: string;
  roles?: VendorAccountRole[];
  deliverySetting: {
    [DeliveryServiceProvider.GOGET]: SupplierDeliverySetting,
    [DeliveryServiceProvider.LALAMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.HAPPYMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.RIDA]: SupplierDeliverySetting,
    [DeliveryServiceProvider.ALLEASYGO]: SupplierDeliverySetting,
    [DeliveryServiceProvider.DELIVERIT]: SupplierDeliverySetting,
    [DeliveryServiceProvider.UPARCEL]: SupplierDeliverySetting,
    [DeliveryServiceProvider.FIVESENDS]: SupplierDeliverySetting,
  };
  autoBookDeliveryOption?: string;
  deliveryFeeOption?: string;
  deliveryFeePercentage?: number;
  supplierHistory?: any;
  isSmartCanteen?: boolean;
  smartCanteenMargin?: number;
  smartCanteenOperatingRadius?: number;
  smartCanteenDeliveryFee?: number;
  smartCanteenMinimumOrder?: number;
  smartCanteenPreorderTime?: number;
  smartCanteenMaxPaxPerDay?: number;
  foodItemHistory?: Object[];
  foodcustomitems?: Object[];
  fooditems?: Object[];
  allowVendorToSeeCustomerPhoneNumber?: boolean;
  showMapEveryWhere?: boolean;
  orderPhoneNumberReveals?: Object[];
  confirmCloseTime?: boolean;
  keywords?: string;
  paymentTerm: 'twice-per-month' | 'once-per-week' |'every-month' = 'twice-per-month';
  smartDiscount: Object[] = [];
  hasReferralAgreement?: boolean;
  pickupPointPicName: string = '';
  pickupPointPicEmailAddress: string = '';
  pickupPointPicContactNumber: any = '';
  overwriteDeliverySetting?: boolean;
  callDeleteMenuSections?: boolean = false;

  geoJsonLoc: {
    type: 'Point',
    coordinates: []
  };

  deliveryFeeBaseTypes: DeliveryFeeBaseType[];
  allowRecurrenceNotification?: boolean;
  liveAtMarketPlace?: Date;
  liveAtSmartCanteen?: Date;
  liveAtReferralLink?: Date;
  inquiryId?: string;

  constructor(values: Object = {}) {
    Object.assign(this, values, {
      reports: _.cloneDeep(_.sortBy(values['reports'], 'createdAt') || [])
    });
    if (_.isNull(this.loc)) {
      this.loc = [0, 0];
    }
    // if enablePickup is undefined then it would be true because default value is true.
    if (_.isUndefined(this.enablePickup)) {
      this.enablePickup = true;
    }

    this.deliverySetting = {
      ...{
        [DeliveryServiceProvider.GOGET]: {
          service: DeliveryServiceProvider.GOGET,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.LALAMOVE]: {
          service: DeliveryServiceProvider.LALAMOVE,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.RIDA]: {
          service: DeliveryServiceProvider.RIDA,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.HAPPYMOVE]: {
          service: DeliveryServiceProvider.HAPPYMOVE,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.ALLEASYGO]: {
          service: DeliveryServiceProvider.ALLEASYGO,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.DELIVERIT]: {
          service: DeliveryServiceProvider.DELIVERIT,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.UPARCEL]: {
          service: DeliveryServiceProvider.UPARCEL,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.FIVESENDS]: {
          service: DeliveryServiceProvider.FIVESENDS,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
      }, ...this.deliverySetting || {}
    };

    this.deliveryFeeBaseTypes = this.deliveryFeeBaseTypes || [];

    this.autoBookDeliveryOption = this.autoBookDeliveryOption || 'bike';

    this.overwriteDeliverySetting = _.isUndefined(this.overwriteDeliverySetting) || this.overwriteDeliverySetting;
  }
}

export interface SupplierDeliverySetting {
  service: string,
  car: boolean,
  bike: boolean,
  van: boolean,
  '4x4_suv': boolean,
  type: 'on-demand' | 'next-day',
  priority: number,
  riderPreBookingTimeInMin: number
}

export class SupplierMenu {
  _id: string;
  index: number;
  name: string;
  active: boolean;
  preorderTime?: number;
  menuSectionIds?: string[];
  minimumOrder?: string;
  menuSections?: SupplierMenuSection[];
}

export class SupplierMenuSection {
  _id: string;
  index: number;
  name: string;
  menuName?: string;
  active: boolean;
  smartCanteenActive: boolean;
  main: boolean;
  minimumShow = 0;
  preorderTime?: number;
  maxQuantityPerCart: number | null;
  smartCanteenDay?: any[] = [];
  smartCanteenDate?: any[] = [];
  marketPlaceDay?: any[] = [];
  marketPlaceDate?: any[] = [];
  flatDeliveryFee?: number;
  isSelected?: boolean;
  rangeFrom?: number;
  rangeTo?: number;
}

export class SupplierPromotionSection {
  _id: string;
  index: number;
  name: string;
  active: boolean;
}

export class SupplierReviews {
  _id: string;
  supplierId: string;
  rating: number;
  comment: string;
  author: string;
  createdAt: Date;
  updatedAt: Date;
  order?: Order;
  foodItemId?: string;
  foodItemName?: string;
  orderItemId?: string;
}

export class Customer {
  _id = '';
  name = '';
  mobileNumber: string;
  email: string;
  status = 'active';
  notificationToken?: string;
  pushToken?: string;
  mobileValidated = false;
  emailValidated = false;
  locationConfirmed = false;
  isSelected =false;
  zoneId: string;
  zoneName: string;
  notes: string;
  address: string;
  preferences = [];
  companyName: string;
  buildingId: string;
  buildingName: string;
  floor: string;
  blockNumber: string;
  unitNumber: string;
  valKey: string;
  fineTuneLocation: string;
  isGold?: boolean; // admin-api virtual field
  subscription: 'none' | 'gold';
  subscriptionEndDate: Date;
  createdAt: Date;
  updatedAt: Date;
  building: Building;
  referralSupplierId: string;
  referralSupplierName: string;
  account?: any;

  birthDate: Date;
  dob: Date;
  age: number;
  ethnicity: string;
  gender: string;
  jobTitle: string;
  companySize: string;

  tagCuisine: string[];
  tagMealType: string[];
  tagDietary: string[];
  tagPriceRange: string;
  loginViaPassword: boolean;
  differentPickup: boolean;
  placeId: string;
  corporateId?: string;
  corporateName?: string;
  departmentId?: string;
  departmentName?: string;
  locationId?: string;
  recipient?:string;
  locationName?: string;
  corporateaccount?: any; // deprecated

  corporate?: Corporate;
  location?: Locations;
  referralSupplier?: Supplier;

  // is corporate admin
  isAdmin?: boolean;

  balance?: number;
  monthlyCreditLine?: number;
  isCorporateAdmin?: boolean;
  corporateIdsView?: string[];
  corporatesView?: {_id: string, name: string}[];
  customer: any;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Building {
  _id = '';
  name = '';
  status = 'active';
  mapType: string;
  addressConfirmed = false;
  zoneId: string;
  zoneName: string;
  placeSublocality: string;
  address: string;
  loc = [];
  // subscription
  gold: boolean;
  ridersCanGoUp: boolean;
  placeId: string;
  noCustomers = 0;
  sublocalityId: string;
  sublocalityName: string;
  sublocality?: SubLocality;
  createdAt: Date;

  // new google maps buildings
  preferred = false;

  loading = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface IMarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

export class IncomingSms {
  _id: Object;
  msisdn: string;
  to: string;
  messageId: string;
  text: string;
  type: string;
  keyword: string;
  message_timestamp: Date;
  isRead: boolean;
  replied: boolean;
  customerName: string;
  customerCompanyName: string;
  customerBuildingName: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Topup {
  _id: string;
  guid: string;
  topupPackageId: string;
  topupPackageName: string;
  benefits: string;
  amountPaid: number;
  creditsAwarded: number;
  customerId: string;
  customerName: string;
  customerMobileNumber: string;
  createdBy: string;
  status = 'processing';
  rejectedMessage: string;
  rejectedError: string;
  orderNumber: string;
  createdAt: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Pxl {
  _id: string;
  link: string;
  linkId: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Nugget {
  _id: string;
  customerId: string;
  customerName: string;
  customerMobileNumber: string;
  createdBy: string;
  amount: number;
  status = 'processing';
  rejectedMessage: string;
  rejectedError: string;
  orderNumber: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Subscription {
  _id: string;
  guid: string;
  subscriptionId: string;
  subscriptionName: string;
  amountPaid: number;
  customerId: string;
  customerName: string;
  customerMobileNumber: string;
  createdBy: string;
  status = 'processing';
  rejectedMessage: string;
  rejectedError: string;
  orderNumber: string;
  dateConfirmed: Date;
  dateInitiated: Date;
  dateTermination: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

// Corporate Dashboard Export
export const CorporatePaymentStatusDropdown = [
  {_id: 'all', name: 'All'}, {_id: 'pending', name: 'Pending'}, {_id: 'paid', name: 'Paid'}
];
export const RefundStatusDropdown = [
  {_id: 'all', name: 'All'}, {_id: 'pending', name: 'Pending'}, {_id: 'accepted', name: 'Accepted'}
  , {_id: 'reject', name: 'Reject'}
  , {_id: 'requested', name: 'Requested'}
];
export const OrderTypesDropdown = [
  {_id: 'all', name: 'All'}, {_id: 'marketplace', name: 'Marketplace'},  {_id: 'smartcanteen', name: 'Smartcanteen'}
];

// Vendor Dashboard Export
export const PaymentStatusDropdown = [
    {_id: 'all', name: 'All'}, {_id: 'pending', name: 'Pending'}, {_id: 'paid', name: 'Paid'}, {
    _id: 'awaiting-corporate-payment',
    name: 'Awaiting corporate payment'
  }
];
export const InvoiceTypeDropdown = [
  {_id: 'chain', name: 'Chain'}, {_id: 'outlet', name: 'Supplier'}];

export const PaymentTermDropdown = [
  {_id: 'once-per-week', name: 'Once per week'}, {_id: 'twice-per-month', name: 'Twice per month'}];

export class VendorDashboard {
  _id: string;
  fromDate: string;
  toDate: string;
  vendor: string;
  supplierId: string;
  chainId: string;
  supplier: { name: string };
  chain: { name: string };
  invoiceAmount: string;
  invoiceNumber: string;
  invoiceType: string;
  paymentStatus: string;
  paymentDate: string;
  isSelected: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class CorporateDashboard {
  _id: string;
  fromDate: string;
  toDate: string;
  vendor: string;
  supplierId: string;
  chainId: string;
  supplier: { name: string };
  chain: { name: string };
  invoiceAmount: string;
  invoiceNumber: string;
  invoiceType: string;
  paymentStatus: string;
  status: string;
  paymentDate: string;
  isSelected: boolean = false;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class RefundDashboard {

  fromDate: string;
  toDate: string;
  vendor: string;
  supplierId: string;
  chainId: string;
  supplier: { name: string };
  chain: { name: string };
  invoiceAmount: string;
  invoiceNumber: string;
  invoiceType: string;
  paymentStatus: string;
  paymentDate: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}


export class Faq {
  _id: string;
  index: number;
  title: string;
  answer: string;
  createdAt: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Term {
  _id: string;
  index: number;
  title: string;
  text: string;
  createdAt: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * When you assign a rider to order, you can use this type.
 */
export class Assignment {
  buildingId: string;
  buildingName: string;
  createdAt: string;
  dailyMenuId: string;
  deliveryId: string;
  deliveryName: string;
  supplierId: string;
  supplierName: string;
  updatedAt: string;
  pickupMessage?: string;
  pickupIndex: number;
  pickupStatus: 'completed' | 'cancelled';
  completedPickupTime?: Date;
  building: any;
  supplier: any;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface ITasksheetCustomer {
  userName: string;
  companyName: string;
  userMobileNumber: string;
  orderId: string;
  blockNumber: string;
  unitNumber: string;
  floor: string;
  userId: string;
  name: string;
  collect: number;
  quantity: string;
  deliveryStatus: 'cancelled' | 'completed';
  deliveryMessage?: string;
  deliveryTime: Date,
  differentPickup: boolean;
  orderNumber: string;
  approaching: boolean;
}

export interface ITasksheetDelivery {
  customers: ITasksheetCustomer[][];
  buildingName: string;
  buildingId: string;
  dropoffIndex: number;
  buildingLoc: string;
}

/**
 * Represents a tasksheet only for one person
 */
export interface ITasksheetFoodItem {
  total: number;
  supplier: object;
  supplierId: string;
  supplierName: string;
  name: string;
  totalGMV: number;
  ready: boolean;
  readyTime: Moment;
  orderNumbers: string;
  warnSupp?: boolean;
}

export interface ITasksheetSupplier {
  total: number,
  pickupId: string;
  pickupMessage?: string,
  pickupStatus: 'completed' | 'cancelled',
  pickupIndex: number,
  completedPickupTime?: string,
  foodItems: Array<ITasksheetFoodItem>;
  minReadyTime: string;
  maxReadyTime: string;
  supplierLoc: string;
}

export class Tasksheet {
  public deliveryName: string;
  public deliveryId: string;
  public collection: Array<any>;
  public suppliers: Array<ITasksheetSupplier>;
  public deliveries: Array<ITasksheetDelivery>;
  public total: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface AppState {
  orders: Array<any>;
  suppliers: Array<Supplier>;
  buildings: Array<Building>;
  promo: Array<Promo>;
}

export interface IOrderRequest {
  date: string;
  datatable: {
    length?: number;
    start?: number;
    columns?: Array<any>;
    order?: Array<any>;
  };
  zone: string;
  timeSlot: 'breakfast' | 'lunch' | 'breakfast' | 'dinner',
  subTimeSlot: 0 | 1 | 2
}

export interface IResponseData<T> {
  kind?: string;
  fields?: string;
  etag?: string;
  id?: string;
  lang?: string;
  updated?: string;
  deleted?: boolean;
  currentItemCount?: Number;
  itemsPerPage?: Number;
  startIndex?: Number;
  totalItems?: Number;
  pageIndex?: Number;
  totalPages?: Number;
  items?: Array<T>,
  users?: Array<User>,
  user?: User,
  roles?: Array<Role>,
  role?: Role
}

export interface IResponseError {
  code?: Number;
  message: string;
  errors?: Array<{
    domain?: string;
    reason?: string;
    message?: string;
    location?: string;
    locationType?: string;
    extendedHelp?: string;
    sendReport?: string;
  }>
}

export interface IResponse<T> {
  apiVersion?: string;
  context?: string;
  id?: string;
  params?: {
    id?: string
  };
  data?: IResponseData<T>;
  error?: IResponseError;
}

export class OrderSummaryItem {
  public totalOrders: number;
  public buildingName: string;
  public buildingId: string;
  public dailyMenuId: string;
  public zoneName: string;
  public zoneId: string;
  public suppliers: Array<any>;
  public orderItems: Array<any>;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class DeliveryTrackingElement {
  public _id: string;
  public deliveryName: string;
  public supplierName: string;
  public timeValue: string;
  public type: string;
  public date: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class SupplierRiderSummary {
  public foods: Array<{
    name: string, total: number, id: string
  }>;
  public riders: Array<{
    name: string, id: string
  }>;

  public supplierName: string;
  public foodRiderMap: Object;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class User {
  public firstname: string;
  public lastname: string;
  public username: string;
  public mobileNumber: string;
  public role?: any;
  public capabilities?: Array<string>;
  public roles: Array<any>;
  public serviceProviders: Array<any>;
  public password: string;
  public _id?: string;
  public setting?: any;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Capability {
  public name: string;
  public key: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Role {
  public _id?: string;
  public name: string;
  public disabled?: boolean;
  public capabilities: Array<any>;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * This is because of the api response types, and refers to
 * the old json styling.
 */
export interface HTTP0<T> {
  items?: Array<T>;
  item?: T;
  data?: T;
  status?: string;
}

export class Promo {
  public _id: string;
  public name: string;
  public credit: Number;
  public platform: ('web' | 'app')[];
  public duration: Number;
  public validFrom?: Date;
  public validTo?: Date;
  public validBetween: { begin: Date, end: Date }[] = [];
  public validFor: string[];
  public building: Building;
  public zonesId?: string[];
  public vendorsId?: string[];
  public validityAmount?: number;
  public validityUnit?: string;
  public minVal?: Number;
  public maxVal?: Number;
  public percentage?: Number;
  public maxValOfPercentage?: Number;
  public category?: string;
  public buildingId?: string;
  public buildingPlaceId?: string;
  public buildingName?: string;
  public usageLimit: Number;
  public paymentMethod: string[];
  public timeSlot: string[];
  public redemptionBySingleUser: Number;
  public active?: boolean;
  public count?: Number;
  public paidBy = 'smartbite';
  public discountTo = 'food';
  public paidById?: string;
  public vendors?: string[];
  public supplierId?: string;
  public foodItemsId?: string[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

/**
 * Since login route for user returns special type of response,
 * we use this definition for api data.items[0] instead of any.
 */
export interface ILoginResponse {
  capabilities: Array<string>;
  user: User,
  token: string;
}

export interface IRestaurantLoginResponse {
  supplier: Supplier,
  token: string;
}

export interface BadgeItem {
  type: string;
  value: string;
}

interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface IGroupCapabilities {
  name: string;
  key?: string;
  items?: IGroupCapabilities[];
}

export interface IFeedBackFoodItem {
  name: string;
  simpleCost?: number;
  _id?: string;
}

export interface IGlobalUpTime {
  uptime: number;
}

export interface IMenu {
  name: string;
  status?: any;
  validFrom?: any;
  validUntil?: any;
  zoneId?: any;
  items: Array<IMenuFood>;
  _id?: string;
}

export interface IFoodItem {
  name: string;
  simpleMOQ?: string;
  simplePriceToSell: any;
}

export interface IMenuFood {
  _id: string;
  foodItem: IFoodItem;
  timeSlot: string;
  sellingPrice: Number;
  status: string;
  orderIndex: any;
}

export interface IResponseError {
  code?: Number;
  message: string;
  errors?: Array<{
    domain?: string;
    reason?: string;
    message?: string;
    location?: string;
    locationType?: string;
    extendedHelp?: string;
    sendReport?: string;
  }>
}

export interface IResponseData<T> {
  kind?: string;
  fields?: string;
  etag?: string;
  id?: string;
  lang?: string;
  updated?: string;
  deleted?: boolean;
  currentItemCount?: Number;
  itemsPerPage?: Number;
  startIndex?: Number;
  totalItems?: Number;
  pageIndex?: Number;
  totalPages?: Number;
  items?: Array<T>
}

export interface IResponse<T> {
  apiVersion?: string;
  context?: string;
  id?: string;
  params?: {
    id?: string
  };
  data?: IResponseData<T>;
  error?: IResponseError;
}

export interface Response {
  success: boolean;
  data?: any;
  error?: {
    code: string;
    description: string,
    error: any;
  };
}

export interface SubTimeSlot {
  main: string; // time slot
  sub: number; // sub time slot
  start: string;
  end: string;
  notificationTime: string;
  notificationTime1: string;
  closing: string;
  closing_2: string;
  cancellation: string;
  readyBy: string;
  active: boolean;
}

export class Setting {
  updatedAt: Date;
  slots: {
    lunch: {
      0: SubTimeSlot,
      1: SubTimeSlot,
      2: SubTimeSlot
    },
    dinner: {
      0: SubTimeSlot,
      1: SubTimeSlot
    }
  };
  suppliers: {
    maxPaxSlot: number,
    sendNewOrdersNotifications: boolean
  };
  topups: {
    index: number;
    name: string,
    description: string,
    amount: number,
    extra: number,
    daysFreeGold: number,
    edit?: boolean;
  }[];
  features: {
    algoliaSearch: boolean
  }

  autoBookDelivery: boolean;
  deliveryFeeBase: number;
  deliveryFeeMinRadius: number;
  deliveryFeePerKm: number;
  mDeliveryFee: number;
  freeDeliveryAboveXAmount: number;// required
  enablePickup: boolean;  // required
  allowDeliveryBooking?: boolean; // required
  deliverySetting: {
    [DeliveryServiceProvider.GOGET]: SupplierDeliverySetting,
    [DeliveryServiceProvider.LALAMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.HAPPYMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.RIDA]: SupplierDeliverySetting,
    [DeliveryServiceProvider.ALLEASYGO]: SupplierDeliverySetting,
    [DeliveryServiceProvider.DELIVERIT]: SupplierDeliverySetting,
    [DeliveryServiceProvider.UPARCEL]: SupplierDeliverySetting,
    [DeliveryServiceProvider.FIVESENDS]: SupplierDeliverySetting,
  };
  autoBookDeliveryOption?: string;
  deliveryFeeOption?: string;
  deliveryFeePercentage?: number;
  deliveryFeeBaseTypes: DeliveryFeeBaseType[];
  constructor(values: Object = {}) {
    if(values){
      Object.assign(this, values);
    }
    // if enablePickup is undefined then it would be true because default value is true.
    if (_.isUndefined(this.enablePickup)) {
      this.enablePickup = true;
    }
    this.deliverySetting = {
      ...{
        [DeliveryServiceProvider.GOGET]: {
          service: DeliveryServiceProvider.GOGET,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.LALAMOVE]: {
          service: DeliveryServiceProvider.LALAMOVE,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.RIDA]: {
          service: DeliveryServiceProvider.RIDA,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.HAPPYMOVE]: {
          service: DeliveryServiceProvider.HAPPYMOVE,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.ALLEASYGO]: {
          service: DeliveryServiceProvider.ALLEASYGO,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.DELIVERIT]: {
          service: DeliveryServiceProvider.DELIVERIT,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.UPARCEL]: {
          service: DeliveryServiceProvider.UPARCEL,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
        [DeliveryServiceProvider.FIVESENDS]: {
          service: DeliveryServiceProvider.FIVESENDS,
          car: false,
          bike: false,
          van: false,
          '4x4_suv': false,
          type: 'on-demand',
          priority: 1,
          riderPreBookingTimeInMin: 30,
        },
      }, ...this.deliverySetting || {}
    };
    this.deliveryFeeBaseTypes = this.deliveryFeeBaseTypes || [];
    this.autoBookDeliveryOption = this.autoBookDeliveryOption || 'bike';
  }
}

export const DefaultFeatureSetting = {
  algoliaSearch: false
};

export class Notification {
  _id: string;
  title: string;
  description: string;
  global: boolean;
  recipients: string[];
  read: string[];
  createdAt: Date;
  isRead: boolean;
}

export class FoodItemIngredient {
  _id: string;
  name: string;
  category: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface LoyaltyPackage {
  _id: string,
  index: number,
  title: string,
  description: string,
  type: string,
  daysFreeGold: number,
  nuggetsAmount: number,
  nuggetsAmountPromo: number,
  nuggetsAmountGold: number,
  credits: number,
  imageUrl: string,
  edit?: boolean
}

export const SupplierTags = () => {
  const supplierTags = [
    '24/7',
    'breakfast',
    'brunch',
    'dinner',
    'lunch',
    'tea time',
    'bakery',
    'bbq',
    'beverages',
    'bouquet',
    'bubble tea',
    'burgers',
    'burritos',
    'cafe',
    'cheese',
    'chicken',
    'chicken rice',
    'chocolate',
    'coffee & tea',
    'curated gift boxes',
    'dessert',
    'dim sum',
    'diy',
    'duck',
    'fast food',
    'fish',
    'fruits',
    'fusion',
    'grill',
    'hampers',
    'healthy',
    'hot pot',
    'ice cream',
    'juice',
    'milk tea',
    'nasi lemak',
    'noodles',
    // 'pantry',
    'pasta',
    'pastries',
    'pizza',
    'poke bowl',
    'porridge',
    'ramen',
    'rice',
    'roti',
    'salad',
    'sandwiches',
    'satay',
    'seafood',
    'smoothies',
    'snack',
    'soup',
    'steak',
    'sushi',
    'vegetarian',
    'whole cakes',
    'wraps'

  ]
  return supplierTags.sort();
};




export const SupplierCuisine = () => {

// const country = environment.currency.country;
  const supplierCuisine =
  [
    'african',
    'american',
    'asian',
    'chinese',
    'european',
    'filipino',
    'gifts',
    'indian',
    'indonesian',
    'japanese',
    'korean',
    'malaysian',
    'mexican',
    'middle eastern',
    'vietnamese',
    'thai',
    'pantry',
  ];
  if (country == 'SG') {
    supplierCuisine.push('singaporean');
 }else{
    supplierCuisine.push('western');
  }

  return supplierCuisine.sort();
};


export const SupplierType = [
  'restaurant',
  'caterer',
  'kitchen',
  'cafe',
  'food truck',
  'hawker stall',
  'fine dining',
  'fast-food',
  'bakery',
  'shop'
];

export const FoodType = [
  'malay', 'chinese', 'indonesian', 'vietnamese', 'thai', 'indian', 'western', 'korean', 'japanese', 'other', 'malaysian',
  'mediterranean', 'mexican', 'middle eastern', 'turkish', 'italian', 'american', 'asian', 'fusion', 'french', 'german', 'singaporean',
  'chinese new year'
];

export const SeasonalTags = () => {

    const seasonalTags = [
      "christmas special",
      "new year feast",
      "valentine treats",
      "easter delights",
      "festive combos",
      "halloween treats",
      "cny special",
      "mother's day special",
      "father's day special",
      "deepavali delights",
      "for her",
      "for him",
      "mooncake festival"
    ];

    if (country == 'SG') {
      seasonalTags.push('singapore national day');
      seasonalTags.push('lunar new year');
    }else if (country == 'PH'){
      seasonalTags.push('philippines independence day');
    }else{
      seasonalTags.push('hari raya feast');
      seasonalTags.push('merdeka');
      seasonalTags.push('malaysia day');
    }

    return seasonalTags.sort();
  };

// remove later
export const MealType = [
  'soup noodles', 'rice', 'salad', 'pasta', 'soup', 'burger', 'sushi', 'burritos',
  'chicken', 'crepes', 'cupcakes', 'curry', 'dim sum', 'fast food', 'fish', 'fried chicken', 'fruits', 'meat', 'noodles', 'seafood',
  'bento', 'street food', 'sweets', 'tacos', 'wraps', 'beverages', 'cakes', 'desserts', 'pizza', 'sandwiches', 'poke bowl', 'sashimi',
  'waffle', 'other', 'doughnuts', 'juice', 'snack', 'sharing platters'
];
// remove later
export const Dietary = [
  { id: 'none', value: 'none', icon: 'assets/icons/none.png' },
  { id: 'vegan', value: 'vegan', icon: 'assets/icons/vegan.png' },
  { id: 'vegetarian', value: 'vegetarian', icon: 'assets/icons/vegetarian.png' },
  { id: 'dairy free', value: 'dairy free', icon: 'assets/icons/dairy-free.png' },
  { id: 'gluten free', value: 'gluten free', icon: 'assets/icons/gluten-free.png' },
  { id: 'nut free', value: 'nut free', icon: 'assets/icons/nut-free.png' },
  { id: 'spicy', value: 'spicy', icon: 'assets/icons/spicy.png' },
  { id: 'chef recommendation', value: 'chef recommendation', icon: 'assets/icons/chef-recommendation.png' },
];

// Item Type new
export const ItemTypes = () =>{
  const ItemTypes =[
    'dessert',
    'drink',
    'main',
    'not perishable',
    'set',
    'side',
    'starters'
  ];
  return ItemTypes.sort();
};


// supplier special formats


// Supplier Items New


export const SpecialFormatsPackages = () => {
  const SpecialFormatsPackages = [
    {'id': 'biodegradable utensils', value: 'biodegradable utensils'},
    {'id': 'high tea service', value: 'high tea service'},
    {'id': 'live stations', value: 'live stations'},
    {'id': 'none', value: '-None-'},
    {'id': 'reusable containers', value: 'reusable containers'},
    {'id': 'seminar catering', value: 'seminar catering'},
    {'id': 'sharing platters', value: 'sharing platters'},
    {'id': 'weddings & special events', value: 'weddings & special events'},
    {'id': 'zero-waste events', value: 'zero-waste events'},
    {'id': 'bento sets', value: 'Bento Sets'},

  ];

  if(country=='SG'){
    SpecialFormatsPackages.push({'id': 'eco-friendly packaging', value: 'eco-friendly packaging'});
    SpecialFormatsPackages.push({'id': 'locally-sourced ingredients', value: 'locally-sourced ingredients'});
    SpecialFormatsPackages.push({'id': 'plant-based menus', value: 'plant-based menus'});
    SpecialFormatsPackages.push({'id': 'canape service', value: 'canape service'});
    SpecialFormatsPackages.push({'id': 'sustainable Seafood', value: 'sustainable seafood'});
    SpecialFormatsPackages.push({'id': 'organic produce', value: 'organic produce'});
  }
  return SpecialFormatsPackages.sort();
};




// Meal Type New


export const MealTypes = () => {
  const MealTypes = [
    'soup noodles',
    'rice',
    'salad',
    'pasta',
    'soup',
    'burger',
    'sushi',
    'burritos',
    'chicken',
    'crepes',
    'cupcakes',
    'curry',
    'dim Sum',
    'fast food',
    'fish',
    'fried chicken',
    'fruits',
    'meat',
    'noodles',
    'seafood',
    'bento',
    'street food',
    'sweets',
    'tacos',
    'wraps',
    'beverages',
    'cakes',
    'desserts',
    'pizza',
    'sandwiches',
    'poke bowl',
    'sashimi',
    'waffle',
    'other',
    'doughnuts',
    'juice',
    'snack',
    'sharing platters'
  ];
  return MealTypes.sort();
};

// dietaries new
export const Dietaries = () => {
  const dietaries = [
    { id: 'chef recommendation', value: 'chef recommendation', icon: 'assets/icons/chef-recommendation.png' },
    { id: 'dairy free', value: 'dairy free', icon: 'assets/icons/dairy-free.png' },
    { id: 'diabetic friendly', value: 'diabetic friendly', icon: 'assets/icons/diabetic-friendly.png' },
    { id: 'gluten free', value: 'gluten free', icon: 'assets/icons/gluten-free.png' },
    { id: 'no beef', value: 'no beef', icon: 'assets/icons/no-beef.png' },
    { id: 'no shellfish', value: 'no shellfish', icon: 'assets/icons/no-shellfish.png' },
    { id: 'none', value: 'none', icon: 'assets/icons/none.png' },
    { id: 'nut free', value: 'nut free', icon: 'assets/icons/nut-free.png' },
    { id: 'spicy', value: 'spicy', icon: 'assets/icons/spicy.png' },
    { id: 'vegan', value: 'vegan', icon: 'assets/icons/vegan.png' },
    { id: 'vegetarian', value: 'vegetarian', icon: 'assets/icons/vegetarian.png' },
  ];

  if (country == 'SG') {
    dietaries.push(
      { id: 'contains pork', value: 'contains pork', icon: 'assets/icons/contains-pork.png' },
      { id: 'jain', value: 'jain', icon: 'assets/icons/jain.png' },
      { id: 'kosher', value: 'kosher', icon: 'assets/icons/kosher.png' }
    );
  } else if (country == 'PH') {
    dietaries.push(
      { id: 'contains pork', value: 'contains pork', icon: 'assets/icons/contains-pork.png' }
    );
  }

  return dietaries.sort((a, b) => a.value.localeCompare(b.value));
};


export const SupplierPriceRange = ['$', '$$', '$$$', '$$$$'];
export const PriceRange = [
  'below ' + currency(2) + '7',
  currency(2) + '7-10',
  currency(2) + '10-15',
  currency(2) + '15-20',
  'above ' + currency(2) + '20'];

export const Ethnicities = [
  'Malay', 'Chinese', 'Indian', 'Other'
];

export const CorporatePermissions = [
  {title: 'Corporate login', permission: 'platform:login'},
  // {title: 'Delayed payments', permission: 'payments:defer'},
  {title: 'SmartBite edit access', permission: 'corporate:editAccess'}
];

export const PaymentTerm = [
  {title: 'Twice per month', value: 'twice-per-month'},
  {title: 'Every Month', value: 'every-month'},

];

export const SmartCanteenDays = [
  {title: 'Sunday', value: 0},
  {title: 'Monday', value: 1},
  {title: 'Tuesday', value: 2},
  {title: 'Wednesday', value: 3},
  {title: 'Thursday', value: 4},
  {title: 'Friday', value: 5},
  {title: 'Saturday', value: 6},
]

export const SupplierStatus = ['new', 'active', 'offline', 'archived', 'accessible'];

export const FoodItemCategory = [

  {value: 'individual pack', disabled: false},
  {value: 'package', disabled: false},
  {value: 'serving', disabled: false},
  {value: 'sharing platters', disabled: false},
  {value: 'tray', disabled: false},
];

export class Career {
  _id: string;
  title: string;
  slug: string;
  available = false;
  category: string;
  location: string;
  contract: string[];
  description: string;
  createdAt: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class HelpCenter {
  _id: string;
  title: string;
  slug: string;
  available = false;
  category: string;
  location: string;
  contract: string[];
  description: string;
  createdAt: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Categories {
  _id: string;
  title: string;
  createdAt: Date;
  slug: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface IMediaFile {
  _id?: string,
  filename?: string,
  mimeType?: string,
  createdAt?: Date,
  file?: string,
  url?: string,
  createdBy: string,
}

export class Chain {
  _id: string;
  name: string;
  slug: string;
  status: string;
  description: string;
  notes: string;
  logo: string;
  margin: number;
  branchCount: number;
  createdAt: Date;
  suppliers?: Supplier[];
  tagCuisine: string[];
  tags: string[];
  promotions: SupplierPromotionSection[] = [];
  menuSections: SupplierMenuSection[] = [];
  menus: SupplierMenu[] = [];
  vendorAccounts: VendorAccount[] = [];
  accounts: string[] = [];
  roles?: VendorAccountRole[];
  updatedAt: Date;
  overrideBranchNames?: boolean;
  unifyBranches?: boolean;
  billingChainLevel?: boolean;
  image: string;
  coverImage: string;
  publicPageLogo: string;
  paymentTerm: 'twice-per-month' | 'once-per-week' = 'twice-per-month';
  tagDietary: string[] = [];
  tagEnvironmentalPolicy: string[] = [];
  tagFormat: string[];
  tagCuisines: string[];
  type: string;
  documents: any[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Locations {
  _id: string;
  affiliateCode: string;
  buildingId: string;
  buildingName: string;
  buildings?: Building;
  floor?: string;
  unitNumber?: string;
  block?: string;
  customerIdPIC?: string;
  customerNamePIC?: string;
  customer?: any;
  availableForDays?: CorporateAllowanceAvailability[];
  departmentId?: string;
  departmentName?: string;
  deliveryRemarks?: string;
  buildingAddress: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class CorporateAllowanceAvailability {
  title: string;
  description: string;
  time: string;
  daysOfWeek: {
    day: number,
    available: boolean,
    discount: number,
    dailyAllowance: number,
    dailyAllowanceType: string,
    restaurants: { supplierId: string, excludeFoods: string[] }[],
    dailyAllowanceWithRole: {available: boolean, dailyAllowance: number, discount: number, mealPlan: string, dailyAllowanceType: string}[],
  }[];
  dates?: {
    date: Date| string,
    available: boolean,
    discount: number,
    dailyAllowance: number,
    dailyAllowanceType: string,
    restaurants: { supplierId: string, excludeFoods: string[] }[],
    dailyAllowanceWithRole: {available: boolean, dailyAllowance: number, discount: number, mealPlan: string, dailyAllowanceType: string}[],
  }[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}


export const BillingCycles = [
  {id: 'every-order', value: 'Every order'},
  {id: 'every-day', value: 'Every day'},
  {id: 'every-week', value: 'Every week'},
  {id: 'bi-weekly', value: 'Bi weekly'},
  {id: 'tri-weekly', value: 'Every 3 week'},
  {id: 'every-month', value: 'Every month'}
];


export const smartCanteenMealPlan = [
  {id: 'default', value: 'Default'},
  {id: 'admins', value: 'Admins'},
  {id: 'managers', value: 'Managers'},
  {id: 'others', value: 'Others'},
];

export const BillingTypes = [
  {id: 'company', value: 'Company'},
  {id: 'requester', value: 'Requester'},
  {id: 'location', value: 'Location'},
  {id: 'custom', value: 'Custom'},
];

export interface CustomFieldMetaDataType {
  label: string;
  fieldType: "text" | "select";
  showInInvoice?: boolean;
  isRequired?: boolean;
  selectOptions?: string[];
  _id?:string;
  numberOfUploaderField?:number;
}
export interface CustomFieldType {
  _id?: string;
  corporateId: string;
  metaData: CustomFieldMetaDataType;
}

export class Corporate {
  _id: string;
  isSelected :boolean;
  name: string;
  billingCycle: string;
  billingType:string;
  address: string;
  payWithinDays: number;
  billingDateStart: Date;
  customers?: Customer[];
  departments?: Department[];
  locations?: Locations[];
  buildings?: any[]; // list of buildings from locations
  corporateAdmins?: CorporateAdmin[];
  headerColor?: string;
  containerColor?: string;
  footerColor?: string;
  employees?: Customer[];
  smartCanteenEmployees?: Customer[];
  smartCanteenDeactivateEmployees?: Customer[];
  smartCanteenPendingEmployees?: Customer[];
  corporateDeactivateEmployees?: Customer[];
  corporatePendingEmployees?: Customer[];
  managerName?: string;
  managerMobileNumber?: string;
  managerEmail?: string;
  invoices?: CorporateReport[];
  quotations?: CorporateReport[];
  showEmployees = false;
  showSmartCanteenEmployees =false;
  showAllowances = false;
  showCorporateCreditLine = false;
  paymentDetails: string;
  acceptLatePaymentTerms: boolean;
  companyNumber: string;
  sstNumber: string;
  restrictedOfSuppliers: boolean;
  supplierLists: string[];
  billingSegments:string[];
  suppliers: Supplier[];
  corporateEmployees?: any[];
  requireIdVerification?: boolean;
  giftPlatformActive?: boolean;
  giftPlatformDesc?: string;
  distributionType?: string;
  pickupEmailId?: string;
  deliveryEmailId?: string;
  defaultDeliveryRemarks?: string;
  invoicePrefix?: string;
  logo?: string;
  canteenLogo?: string;
  slotLimitValidation?: boolean = true;
  invoicesWithoutBreakDown?: boolean = false;
  sendInvitationEmail?: boolean = true;
  discountedDeliveryFee?: boolean = false;
  giftItems?: any;
  giftStores?: any[];
  smartCanteenDeliveryFee?: number = null;
  canteenCreditsAmountThresholdLimit?: number = 0;
  monthlyCreditLimit?: number = 0;
  canteenCreditsAmount?: number = 0;
  canteenTopupAmount?: number = 0;
  sendQRCodeCustomerEmail?: boolean;
  sendGroupedInvoicesEmail?: boolean;
  changeLocationSmartCanteen?: boolean;
  hasSingleSelectedVendor?: boolean;
  showPriceRangeGroupOrder?: boolean = true;
  sendNotifyToPICForBalance?: boolean;
  startingDaySmartCanteen?: number;
  smartCanteenTopUp?: any[];
  picId?: string;
  canteenReminderSet?: {hours: number, emailType: string}[];
  inquiryId?: string;
  // canteen max allowance markup
  activeCanteenMarkup?: boolean = false;
  allowanceGivenToEmployee?: number;
  maxMarkup?: number;
  deliverySetting?: {
    [DeliveryServiceProvider.GOGET]: SupplierDeliverySetting,
    [DeliveryServiceProvider.LALAMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.HAPPYMOVE]: SupplierDeliverySetting,
    [DeliveryServiceProvider.RIDA]: SupplierDeliverySetting,
    [DeliveryServiceProvider.ALLEASYGO]: SupplierDeliverySetting,
    [DeliveryServiceProvider.DELIVERIT]: SupplierDeliverySetting,
    [DeliveryServiceProvider.UPARCEL]: SupplierDeliverySetting,
    [DeliveryServiceProvider.FIVESENDS]: SupplierDeliverySetting,
  };
  sharingSmartCanteenAllowance?: boolean;
  restrictedAllowanceForSlot?: number;
  smartCanteenServiceCharges?: number;
  restrictedSlotForMonth?: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Department {
  _id: string;
  name: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class Inquiries {
  _id: string;
  type: 'location' | 'supplier' | 'catering' | 'rider'| 'corporate-new-inquiry';
  data: any;
  quotations: any[];
  createdAt: Date;
  updatedAt: Date;
  handled: boolean;
  createdDate?: string;
  name?: string;
  company?: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class CorporateAdmin {
  _id: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  corporate: string;
  corporateId: string;
  corporateName: string;
  permissions?: string[];
  locations?: string[];
  locationId: string[];
  password: string;
  deleted?: boolean;
  isSelected?: boolean;
  createdAt: Date;
  updatedAt: Date;
  accountType: 'smart-canteen' | 'marketplace-enhancement' | 'both' | null;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class CorporateAllowance {
  _id: string;
  startDate: Date;
  endDate: Date;
  dailyAllowance: number;
  discount: number;
  allowanceDate?: { start: Date, end: Date };
  status: 'active' | 'ended' | 'ready';
  location?: object;
  locationId: string[];
  locationName: string;
  availableForDays: CorporateAllowanceAvailabilityDays[];
  availableForSlots: CorporateAllowanceAvailabilitySlots[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class GitRelease {
  name: string;
  body: Array<any>;
  created_at: Date;
  repository: string;
  latest: boolean;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class CorporateAllowanceAvailabilityDays {
  day: number;
  available: boolean;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export class CorporateAllowanceAvailabilitySlots {
  timeSlot: string;
  subTimeSlots: {
    available: boolean,
    title: number
  }[];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }
}

export interface CorporateReport {
  _id: string;
  corporateId: string;
  corporateOrderId: string;
  corporateAllowanceId: string;
  itemType: string;
  name: string;
  type: string;
  size: number;
  fromDate: Date;
  toDate: Date;
  visible: boolean;
  status: string;
  reportType: string;

  corporate?: any[];
  documents?: any[];
}

export const PaymentStatus = [
  {'id': 'paid', value: 'Paid'},
  {'id': 'unpaid', value: 'UnPaid'},
  {'id': 'paylater', value: 'Pay later'},
  {'id': 'failed', value: 'Failed'},
  {'id': 'pending', value: 'Pending'},
  {'id': 'open', value: 'Open'}
];

export const PaymentMethod = [
  {'id': 'paid-partial', value: 'Partial Paid'},
  {'id': 'paid-via-topup', value: 'Paid via Topup'},
  {'id': 'cash-on-delivery', value: 'Cash on Delivery'},
  {'id': 'transfer-payment', value: 'Transfer Payment'},
  {'id': 'paid-online', value: 'Paid Online'},
  {'id': 'creditline', value: 'Paid via CreditLine'},

];

export const ServiceProviders = [
  {'id': 'goget', value: 'Goget'},
  {'id': 'lalamove', value: 'Lalamove'},
  {'id': 'rida', value: 'Rida'},
  {'id': 'deliverit', value: 'Deliverit'},
  {'id': '5sends', value: '5sends'},
  {'id': 'happymove', value: 'Happymove'},
  {'id': 'alleasygo', value: 'All Easy Go'},
  {'id': 'uparcel', value: 'Uparcel'},
];

export const OrderType = [
  {'id': 'regular', value: 'E-commerce'},
  {'id': 'group', value: 'Group (share link)'},
  {'id': 'smartcanteen', value: 'SmartCanteen'},
  {'id': 'catering', value: 'Catering'}
];

export const OrderStatus = [
  {'id': 'draft', value: 'Draft'},
  {'id': 'pending', value: 'Pending'},
  {'id': 'overdue', value: 'Overdue'},
  {'id': 'accepted', value: 'Accepted'},
  {'id': 'cancelled', value: 'Cancelled'},
  {'id': 'completed', value: 'Completed'}
];

export class VendorAccount {
  name: string;
  firstName: string;
  lastName: string;
  protected: boolean;
  supplierId: string;
  phoneNumber: string;
  email: string;
  permission?: VendorAccountRole;
  role: string;
  password: string;
  createdAt: string;
  _id?: string;
  type: 'vendor' | 'chain' = 'vendor';

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class VendorAccountRole {
  _id?: string;
  title: string;
  description: string;
  permissions: string[];
  supplierId?: string;
  createdById?: string;
  protected?: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export interface GiftStore {
  _id: string,
  name: string,
  maxSlot: number,
  startDate: string,
  endDate: string,
  password: string
}

export interface DeliveryFeeBaseType {
  _id: string,
  vehicle: string,
  fee: number,
  appliesAtPax: number,
  feePerKm: number,
  feeD2D:number,
  doorToDoor: boolean
}


export const SupplierFormat = [
  '1-day event ',
  'buffet',
  'bundles',
  'can provide events venue',
  'canape/cocktail',
  'chilled meals',
  'frozen meals',
  'in-house delivery available',
  'item personalization',
  'live station',
  'lunch box',
  'mini buffet',
  'packed meals',
  'seminar/training',
  'serviced buffet',
  'sharing platters',
  'tea-time',
  'none'
];

// export const SupplierDietary = [
//   {'id':  'none', value: '-None-'},
//   {'id': 'dairy free', value: 'Dairy free'},
//   {'id': 'diabetic friendly', value: 'Diabetic friendly'},
//   {'id': 'gluten free', value: 'Gluten free'},
//   {'id': 'halal', value: 'Halal'},
//   {'id':  'jain', value: 'Jain'},
//   {'id': 'kosher', value: 'Kosher'},
//   {'id': 'muslim friendly', value: 'Muslim friendly'},
//   {'id': 'nut free', value: 'Nut free'},
//   {'id': 'no beef', value: 'No beef'},
//   {'id': 'paleo', value: 'Paleo'},
//   {'id': 'vegan', value: 'Vegan'},
//   {'id': 'vegetarian', value: 'Vegetarian'}


// ];

export const SupplierDietary = [
  { id: 'none', value: '-None-', icon: 'assets/icons/none.png' },
  { id: 'dairy free', value: 'Dairy free', icon: 'assets/icons/dairy-free.png' },
  { id: 'diabetic friendly', value: 'Diabetic friendly', icon: 'assets/icons/diabetic-friendly.png' },
  { id: 'gluten free', value: 'Gluten free', icon: 'assets/icons/gluten-free.png' },
  { id: 'halal', value: 'Halal', icon: 'assets/icons/halal.png' },
  { id: 'jain', value: 'Jain', icon: 'assets/icons/jain.png' },
  { id: 'kosher', value: 'Kosher', icon: 'assets/icons/kosher.png' },
  { id: 'muslim friendly', value: 'Muslim friendly', icon: 'assets/icons/muslim-friendly.png' },
  { id: 'nut free', value: 'Nut free', icon: 'assets/icons/nut-free.png' },
  { id: 'no beef', value: 'No beef', icon: 'assets/icons/no-beef.png' },
  { id: 'paleo', value: 'Paleo', icon: 'assets/icons/paleo.png' },
  { id: 'vegan', value: 'Vegan', icon: 'assets/icons/vegan.png' },
  { id: 'vegetarian', value: 'Vegetarian', icon: 'assets/icons/vegetarian.png' },
  { id: 'spicy', value: 'Spicy', icon: 'assets/icons/spicy.png' },
  { id: 'chef recommendation', value: 'Chef Recommendation', icon: 'assets/icons/chef-recommendation.png' },
];

export const EnvironmentalPolicy = [
  {'id': 'biodegradable utensils', value: 'biodegradable utensils'},
  {'id': 'high tea service', value: 'high tea service'},
  {'id': 'live stations', value: 'live stations'},
  {'id': 'none', value: '-None-'},
  {'id': 'reusable containers', value: 'reusable containers'},
  {'id': 'seminar catering', value: 'seminar catering'},
  {'id': 'sharing platters', value: 'sharing platters'},
  {'id': 'weddings & special events', value: 'weddings & special events'},
  {'id': 'zero-waste events', value: 'zero-waste events'},
  {'id': 'bento sets', value: 'Bento Sets'},
];

export const orderItemStatus = [
  {'id': 'pending', value: 'Pending'},
  {'id': 'overdue', value: 'Overdue'},
  {'id': 'cancelled', value: 'Cancelled'},
];


export interface CustomFieldValue {
  metaData: CustomFieldMetaDataType,
  value: string,
  _id?: string,
  type:string,
  filePreview?: string;
}

export interface CustomFieldType {
  _id?: string;
  corporateId: string;
  metaData: CustomFieldMetaDataType;
}
